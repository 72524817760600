const ErrorItemsNode = ({ errors }) => {

  let newErrors = errors?.filter(error => error !== undefined)

  if (newErrors) {
    return (
      <>
        {newErrors.map((item, key) => (
          <div className="capitalize font-size-1" key={`${key}`}>
            { item }
          </div>
        ))}
      </>
    )
  } else {
    return <div className="font-size-1">{'Unexpected error occurs'}</div>
  }
}

export default ErrorItemsNode

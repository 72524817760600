import queryString from 'query-string'
import randomstring from 'randomstring'
import pkceChallenge from 'pkce-challenge'
import { sleep, getCountrySelectorData } from '@/lib/helpers'
import { navClickGTM } from '@/lib/gtm'
function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}
function base64urlencode(a) {
  var str = ''
  var bytes = new Uint8Array(a)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}
async function generateCodeChallengeFromVerifier(v) {
  var hashed = await sha256(v)
  var base64encoded = base64urlencode(hashed)
  return base64encoded
}
const SignInLink = ({ text = 'sign in', linkClass = '', linkId = '', noTab = 'false' }) => {
  const onSignIn = async () => {
    try {
      const savedCountry = getCountrySelectorData()
      // countryData.pagePath = pagePath
      const state = randomstring.generate()
      let challenge = pkceChallenge()
      let code_challenge = await generateCodeChallengeFromVerifier(challenge.code_verifier)

      const configAuth = {
        // tester_id: 'gore_na_hiren_dev_1',
        // server: 'na.ssoauthts.goreweardev.com',
        redirect_uri: `${process.env.NEXT_PUBLIC_BASE_URL}/${savedCountry?.prefix}/auth-callback`,
        // email_domain: null,
        // default_password: null,
        client_id: process.env.NEXT_PUBLIC_PING_LOGIN_CLIENT,
        code_challenge: code_challenge,
        ui_locales: savedCountry?.locale,
        code_challenge_method: 'S256',
        response_type: 'code',
        scope: 'openid profile',
        state: state,
      }
      const pkceData = {
        code_challenge: challenge.code_challenge,
        code_verifier: challenge.code_verifier,
        state: state,
      }
      localStorage.setItem('gore-na:pkceData', JSON.stringify(pkceData))
      console.debug('configAuth', configAuth)
      await sleep(300)
      let query = queryString.stringify(configAuth, {
        sort: false,
        encode: false,
      })
      let AuthRedirect = `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/as/authorization.oauth2?${query}`
      if (0) {
        window.open(AuthRedirect, '_blank').focus()
      } else {
        window.location.replace(AuthRedirect)
      }
    } catch (err) {
      // Handle Error Here
      // console.log('onSignOut errObj', err)
    }
  }

  // If noTab is 'true', tabIndex is set to -1 as on load
  if (noTab == 'true') {
    return (
      <>
        <a
          tabIndex="-1"
          data-href="#"
          className={`${linkClass} cursor-pointer`}
          id={`${linkId}`}
          onClick={(e) => {
            e.preventDefault()
            navClickGTM('', 'Login', 'Login', 'header')
            onSignIn()
          }}
        >
          {text}
        </a>
      </>
    )
  } else {
    return (
      <>
        <a
          tabIndex="0"
          data-href="#"
          className={`${linkClass} cursor-pointer`}
          id={`${linkId}`}
          onClick={(e) => {
            e.preventDefault()
            navClickGTM('', 'Login', 'Login', 'header')
            onSignIn()
          }}
        >
          {text}
        </a>
      </>
    )
  }
}
export default SignInLink

import IconLib from '@/components/icon/icon-lib'

const Icon = ({ size, width, height, className, fill, icon, viewBox, onClick, style ={} }) => {
  return (
    <>
      <svg
        style={style}
        width={`${size || width}`}
        height={`${size || height}`}
        className={className}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: `${IconLib[icon]}` }}
      ></svg>

    <style jsx> {
      `
        .top-21 {
          top: 21px;
        }

        .bottom-30 {
          bottom: 30px;
        }

        .right-20 {
          right:20px
        }

        .right-14 {
          right: 14px;
        }
      `
      }
    </style>
    </>


  )
}

export default Icon
